import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { DATALAYER_EVENT } from './dataLayerConstants';

var getInstance = function getInstance() {
  var dl = window.dataLayer = window.dataLayer || [];
  return dl;
};

export var extractLinkClickEventData = function extractLinkClickEventData(link) {
  if (!link) return;
  var eventName = link.dataset && link.dataset.trackClickEvent || DATALAYER_EVENT.CTA_CLICK;
  var actionUrl = link.href;
  var actionText = link.text;
  var actionCategory = link.dataset && link.dataset.trackClickEventCategory;
  /* Identify location by traversing up tree to find if in one of the following:
      - a section with a name
      - header
      - footer
  */

  var parent = link.parentElement;
  var actionSection; // Check if Element.closest is supported

  if (parent.closest) {
    // identify parent container name
    try {
      var container = parent.closest('section[name], header, footer');

      if (container) {
        actionSection = (container.getAttribute('name') || container.id || container.tagName).toLowerCase();
      }
    } catch (e) {// ignore action location if parent lookup fails / not supported
    }
  }

  return {
    eventName: eventName,
    eventData: {
      actionUrl: actionUrl,
      actionText: actionText,
      actionSection: actionSection,
      actionCategory: actionCategory
    }
  };
};
export var sendEvent = function sendEvent(event) {
  var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var dl = getInstance();

  var dlEvent = _objectSpread(_objectSpread({}, eventData), {}, {
    event: event
  });

  dl.push(dlEvent);
};
export var sendRegisterSubmitEvent = function sendRegisterSubmitEvent() {
  return sendEvent(DATALAYER_EVENT.REGISTER_SUBMIT);
};
export var sendRegisterSuccessEvent = function sendRegisterSuccessEvent(_ref) {
  var userId = _ref.userId;
  return sendEvent(DATALAYER_EVENT.SIGN_UP, {
    page: {
      path: '/register-success'
    },
    userId: userId
  });
};
export var sendCtaClickEvent = function sendCtaClickEvent(_ref2) {
  var actionUrl = _ref2.actionUrl,
      actionText = _ref2.actionText,
      actionSection = _ref2.actionSection,
      actionCategory = _ref2.actionCategory;
  return sendEvent(DATALAYER_EVENT.CTA_CLICK, {
    // CTA Link URL
    actionUrl: actionUrl,
    // CTA Link Text
    actionText: actionText,
    // CTA Location
    actionSection: actionSection,
    actionCategory: actionCategory
  });
};
export var sendTooltipClickEvent = function sendTooltipClickEvent(_ref3) {
  var actionText = _ref3.actionText,
      actionSection = _ref3.actionSection,
      actionCategory = _ref3.actionCategory,
      actionName = _ref3.actionName;
  return sendEvent(DATALAYER_EVENT.TOOLTIP_CLICK, {
    actionText: actionText,
    actionSection: actionSection,
    actionCategory: actionCategory,
    actionName: actionName
  });
};
export var sendGACustomEvent = function sendGACustomEvent(_ref4) {
  var eventAction = _ref4.eventAction,
      eventCategory = _ref4.eventCategory,
      eventLabel = _ref4.eventLabel,
      eventValue = _ref4.eventValue;
  return sendEvent(DATALAYER_EVENT.GA_CUSTOM, {
    eventAction: eventAction,
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventValue: eventValue
  });
};