/** Datalayer Event names (use snake case) */
export var DATALAYER_EVENT = {
  /** GA4: Standard Signup event name - Successful signup */
  SIGN_UP: 'sign_up',

  /** Custom event - Register Submit click */
  REGISTER_SUBMIT: 'register_submit',

  /** Custom Event: Call To Action (CTA) clicks  */
  CTA_CLICK: 'cta_click',

  /** Custom Event: Tooltip click */
  TOOLTIP_CLICK: 'tooltip_click',

  /** Custom Event: GA Custom event */
  GA_CUSTOM: 'GAEvent'
};
export var DATALAYER_FIELD = {
  ACTION_CATEGORY: {
    REGISTER: 'register',
    LOGIN: 'login',
    TEMPLATE: 'template',
    SOCIAL: 'social-share',
    GUIDE: 'guide'
  }
};